.success {
  background-color: lightgreen;
}

.processing {
  background-color: yellow;
}

.failed {
  background-color: pink;
}