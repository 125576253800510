
.stack-on-row:nth-child(4),
.stack-on-row:nth-child(6) {
  background-color: #d8d8d8;
}

.stack-on-row:nth-child(4) div, 
.stack-on-row:nth-child(6) div{
    background-color: transparent;
}

.stack-on-row .css-bhp9pd-MuiPaper-root-MuiCard-root {
    box-shadow: none;
}

.stack-on-row{
    align-items: center;
    padding: 30px 0 30px 0;
    display: flex;
    min-height: 400px;
    flex-direction: row;
}

.stack-on-row div h3{
    font-size: 50px;
    text-align: center;
    width: 180px;
}

.event-cards-group{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding-right: 19px;
}
