.number-TextField input{
    font-size: 30px;
    padding: 6px;
    text-align: center;
}


.batch-release-ul {
    list-style: none;
    font-size: 25px;
    padding: 0px 20px 0 20px;
    margin-bottom: 10px;
}

.batch-release-title {
    font-size: 40px !important;
    padding-bottom: 0px !important;
    font-weight: bolder !important;
    margin: 20px 0 0 20px !important;
}