
.box-temp{
  background-color: black; 

}
@media (orientation: landscape) {
    
       .box-temp{
        /* background-color: black; */
        padding-left: 25%;
      }
    .box-temp div{
      padding-top: 0 !important;
  }
    
  }
  
  @media (orientation: portrait) {
    .box-temp div {
        height: 70% !important;
        width: 100% !important;
        padding-top: 30% !important;
    }
    .box-temp {
       height: 980px !important;
  }
  }