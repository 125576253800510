.event-card {
    min-height: 300px;
    height: fit-content;
    padding: 30px;
    background-color: white !important;
    width: 500px;
    border: 1px solid;
    font-family: Arial, Helvetica, sans-serif;
}

.event-card h1{
    margin: 0;
    font-size: 25px;
}

.event-card div div p{
    font-size: 15px;
}

.event-card div div {
    padding-left: 28px;
    justify-content: flex-end;
}


.event-timeslot {
    font-size: 30px;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0 !important;
}

.event-timeslot div button{
    width:160px;
    height: 50px;
    font-size: 20px;
}

.event-timeslot div{
    padding-left: 0 !important;
}

.event-card-timeslot{
    margin-bottom: 10px;
    padding-left: 0 !important;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    font-size: 30px;
}

.event-card-amountLeft{
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
}

.last-called {
    font-size: 10pt;
}