.big-container-margin{
    margin: 30px;
    margin-bottom: 0;
}

.big-container-margin label {
    font-size: 25px;
    line-height: 27px;
    margin-left: -6px;
}


.date-picker div input{
    height: 2em !important;
    font-size: 25px  !important;
}

.date-picker div{
    flex-direction: row-reverse;
}

.search-container{
    text-align: center !important;
    padding-left: 20px;
}

.search-container button{
    background: #3396ff;
}

.search-date {
    height: 66px;
    width: 100%;
}

.update-text{
    margin-top: 5px !important;
    font-size: 20px  !important;
}

.stack-container{
    margin-top: 10px;
    height: 75px !important;
}

.stack-container button{
    font-size: 40px;
}

